import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import YouTube from 'react-youtube';
export const _frontmatter = {
  "path": "/blog/drupalsouth-2021-sponsor-showcase",
  "date": "11 November 2021",
  "title": "DrupalSouth '21 - Sponsor Showcase",
  "summary": "In this DrupalSouth 2021 sponsor showcase, Nick Schuch (Lead Architect), demonstrated how the Skpr hosting platform simplifies the deployment and orchestration of complex decoupled applications",
  "author": "Nick Schuch",
  "tag": "Presentation",
  "tagColor": "green",
  "tags": [{
    "name": "meetup"
  }, {
    "name": "presentation"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Overview`}</h2>
    <p>{`Deploying Decoupled applications onto cloud hosting platforms can be difficult because of how many moving components are involved eg. Frontend Application(s), Content API etc`}</p>
    <p>{`In this DrupalSouth 2021 sponsor showcase, Nick Schuch (Lead Architect), demonstrates how the Skpr hosting platform simplifies the deployment and orchestration of complex decoupled applications.`}</p>
    <h2>{`Key Points`}</h2>
    <ul>
      <li parentName="ul">{`Highly available hosting solution backed by Kubernetes and AWS Managed Resources`}</li>
      <li parentName="ul">{`Built to empower developers`}</li>
      <li parentName="ul">{`Skpr supports complex hosting architectures eg. A Drupal site that indexes content into Elasticsearch and is visualized via multiple React applications`}</li>
      <li parentName="ul">{`Skpr provides consistent tooling for packaging, deploying, and configuring your decoupled applications`}</li>
      <li parentName="ul">{`Built-in collaboration with `}<a parentName="li" {...{
          "href": "https://nsw.gov.au"
        }}>{`NSW.gov.au`}</a></li>
    </ul>
    <h2>{`Recording`}</h2>

    <YouTube videoId="z5gbLfHqPx4" opts={{
      height: '420',
      width: '100%',
      playerVars: {
        start: '979'
      }
    }} mdxType="YouTube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      